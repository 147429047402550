import React, { useCallback, useEffect, useState, FC } from "react";
import classNames from "classnames";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ArrowIcon } from "../../../assets/svgComponents";
import useDimensions from "../../../hooks/useDimensions";
import Button from "../../Button/Button";
import DataContainer from "../../DataContainer/DataContainer";
import { IPoolData } from "../constants/types";
import useContextSelector from "../../../contexts/contextSelector";
import address from "../../../constants/address";
import StepAppDistributorABI from "../../../constants/StepAppDistributor.json";
import StakingABI from "../../../constants/StepAppStaking.json";
import { STAKING_API_ENDPOINT } from "../../../utils/api";
import { isTechnicalWorks } from "../../../constants/texts";

interface IPoolComponent {
    poolsData: IPoolData[];
    isLoading: boolean;
    // rewardsCount: number | null;
    // isLoadingRewards: boolean;
}

interface IClaimInfo {
    index: string;
    address: string;
    amount: string;
    proofs: string[];
}

const stepappDistributorSCAddress = address.STEP_APP_DISTRIBUTOR;
const API_URL = STAKING_API_ENDPOINT;
const invalidAprValues = ["nan", "infinity"];

const PoolsComponent: FC<IPoolComponent> = ({ poolsData, isLoading }) => {
    const [isClaimTxWaiting, setIsClaimTxWaiting] = useState(false);

    const [isLoadingClaim, setIsLoadingClaim] = useState(true);
    const [claimInfo, setClaimInfo] = useState<IClaimInfo | null>(null);

    const [isLoadingDistributorState, setIsLoadingDistributorState] = useState(true);
    const [isPausedDistributor, setIsPausedDistributor] = useState(true);

    const [isLoadingClaimState, setIsLoadingClaimState] = useState(true);
    const [isClaimed, setIsClaimed] = useState(false);

    const [isLoadingDecimals, setIsLoadingDecimals] = useState(false);
    const [fitfiDecimals, setFitfiDecimals] = useState<number | null>(null);

    const [activeElement, setActiveElement] = useState(0);
    const [allElements, setAllElements] = useState(poolsData.length);
    const [elementScrollSize, setElementScrollSize] = useState(417);

    const { web3Provider: web3, currentAddress, onConnect } = useContextSelector((state) => state.web3Provider);
    // const { web3Provider: web3, onConnect } = useContextSelector((state) => state.web3Provider);
    // const currentAddress = "0x87f1cd0edf6abf77bee26d87d8a9e3837f17ed06";
    // const navigate = useNavigate();

    const { width } = useDimensions();

    const incrementActiveElement = useCallback(() => {
        if (activeElement <= -1) {
            setActiveElement(activeElement + 1);
        }
    }, [activeElement]);

    const decrementActiveElement = useCallback(() => {
        if (Math.abs(activeElement) < allElements) {
            setActiveElement(activeElement - 1);
        }
    }, [activeElement, allElements]);

    // const onClickStakeHandler = () => {
    //     navigate({ pathname: "/" });
    // };

    const onClickClaimHandler = async () => {
        try {
            if (!claimInfo || !claimInfo.proofs.length || !currentAddress) {
                return;
            }
            setIsClaimTxWaiting(true);
            const contract = new web3.eth.Contract(StepAppDistributorABI as any, stepappDistributorSCAddress);
            await contract.methods
                .claim(claimInfo.index, claimInfo.address, claimInfo.amount, claimInfo.proofs)
                .send({ from: currentAddress });
            await getIsClaimedState();
        } catch (error) {
            console.error(error);
        } finally {
            setIsClaimTxWaiting(false);
        }
    };

    const onClickRestakeHandler = async () => {
        try {
            if (!claimInfo || !claimInfo.proofs.length || !currentAddress) {
                return;
            }
            setIsClaimTxWaiting(true);
            const contract = new web3.eth.Contract(StepAppDistributorABI as any, stepappDistributorSCAddress);
            await contract.methods
                .claimAndStake(claimInfo.index, claimInfo.address, claimInfo.amount, claimInfo.proofs)
                .send({ from: currentAddress });
            await getIsClaimedState();
        } catch (error) {
            console.error(error);
        } finally {
            setIsClaimTxWaiting(false);
        }
    };

    const onScrollHandler = useCallback(
        (event: WheelEvent) => {
            // event.preventDefault();
            if (event.deltaY > 0) {
                decrementActiveElement();
            }
            if (event.deltaY < 0) {
                incrementActiveElement();
            }
        },
        [incrementActiveElement, decrementActiveElement]
    );

    const scrollToActiveElement = useCallback(() => {
        const scrollContent = document.getElementById("pools-scroll");
        if (scrollContent) {
            // scrollContent.style.transform = `translateX(${activeElement * elementScrollSize}px)`;
            scrollContent.scrollTo(Math.abs(activeElement) * elementScrollSize, 0);
        }
    }, [activeElement, elementScrollSize]);

    const addScrollHandler = useCallback(() => {
        const scrollContentContainer = document.getElementById("pools-container");
        if (scrollContentContainer) {
            scrollContentContainer.addEventListener("wheel", onScrollHandler);
        }
    }, [onScrollHandler]);

    const removeScrollHandler = useCallback(() => {
        const scrollContentContainer = document.getElementById("pools-container");
        if (scrollContentContainer) {
            scrollContentContainer.removeEventListener("wheel", onScrollHandler);
        }
    }, [onScrollHandler]);

    const getIsPausedState = useCallback(async () => {
        try {
            setIsLoadingDistributorState(true);
            const contract = new web3.eth.Contract(StepAppDistributorABI as any, stepappDistributorSCAddress);
            const response = await contract.methods.paused().call();
            setIsPausedDistributor(response);
            setIsLoadingDistributorState(false);
        } catch (error) {
            console.error(error);
        }
    }, [web3]);

    const getIsClaimedState = useCallback(async () => {
        try {
            if (!claimInfo) {
                return;
            }
            setIsLoadingClaimState(true);
            const contract = new web3.eth.Contract(StepAppDistributorABI as any, stepappDistributorSCAddress);
            const isClaimedState = await contract.methods.isClaimed(claimInfo?.index).call();
            setIsClaimed(isClaimedState);
            setIsLoadingClaimState(false);
        } catch (error) {
            console.error(error);
        }
    }, [web3, claimInfo]);

    const getUserClaimInfo = useCallback(async () => {
        try {
            if (!currentAddress) {
                return;
            }
            setIsLoadingClaim(true);
            const response = await axios.get(`${API_URL}users/claim/${currentAddress}`);
            if (response.status < 300) {
                setIsLoadingClaim(false);
                setClaimInfo(response.data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [currentAddress]);

    const getFitfiTokenDecimals = useCallback(async () => {
        try {
            setIsLoadingDecimals(true);
            const stepStakeContract = new web3.eth.Contract(StakingABI as any, address.STEP_APP_STAKING);
            const newDecimals = await stepStakeContract.methods.decimals().call();
            if (+newDecimals) {
                setFitfiDecimals(+newDecimals);
                setIsLoadingDecimals(false);
            }
        } catch (error) {
            console.error(error);
        }
    }, [web3]);

    useEffect(() => {
        getFitfiTokenDecimals();
    }, [getFitfiTokenDecimals]);

    useEffect(() => {
        getIsPausedState();
    }, [getIsPausedState]);

    useEffect(() => {
        getUserClaimInfo();
    }, [getUserClaimInfo]);

    useEffect(() => {
        scrollToActiveElement();
    }, [scrollToActiveElement]);

    useEffect(() => {
        if (width > 600) {
            setElementScrollSize(490);
        } else {
            setElementScrollSize(width - 24 * 2 + 8);
        }
    }, [width, poolsData]);

    useEffect(() => {
        setAllElements(poolsData.length - Math.floor(width / elementScrollSize));
    }, [width, elementScrollSize, poolsData]);

    useEffect(() => {
        getIsClaimedState();
    }, [getIsClaimedState]);

    // useEffect(() => {
    //     addScrollHandler();
    //     return () => removeScrollHandler();
    // }, [addScrollHandler, removeScrollHandler]);

    const tokenConverter = (amount: number | undefined, decimals: number | null, isClaimedRewards: boolean) => {
        if (!amount || !decimals || isClaimedRewards) {
            return 0;
        }
        if (decimals) {
            const newAmount = amount / 10 ** decimals;
            return newAmount;
        }
        return amount;
    };

    const renderPoolsButton = () => {
        if (
            isLoadingClaim ||
            isLoadingDecimals ||
            isLoadingDistributorState ||
            isLoadingClaimState ||
            isClaimTxWaiting
        ) {
            return (
                <>
                    <Button className="pools__button _disabled" disabled>
                        Loading...
                    </Button>
                    <Button className="pools__button _disabled" disabled>
                        Loading...
                    </Button>
                </>
            );
        }
        if (!isPausedDistributor && (!claimInfo?.proofs.length || isClaimed)) {
            return (
                <>
                    <Button disabled={isTechnicalWorks} className="pools__button _disabled">
                        Restake
                    </Button>
                    <Button className="pools__button _disabled" disabled>
                        Claim
                    </Button>
                </>
            );
        }
        if (!isPausedDistributor) {
            return (
                <>
                    <Button disabled={isTechnicalWorks} className="pools__button" onClick={onClickRestakeHandler}>
                        Restake{" "}
                        {tokenConverter(Number(claimInfo?.amount), fitfiDecimals, isClaimed) > 0
                            ? `${tokenConverter(Number(claimInfo?.amount), fitfiDecimals, isClaimed).toLocaleString(
                                  "en"
                              )} FITFI`
                            : null}
                    </Button>
                    <Button disabled={isTechnicalWorks} className="pools__button" onClick={onClickClaimHandler}>
                        Claim{" "}
                        {tokenConverter(Number(claimInfo?.amount), fitfiDecimals, isClaimed) > 0
                            ? `${tokenConverter(Number(claimInfo?.amount), fitfiDecimals, isClaimed).toLocaleString(
                                  "en"
                              )} FITFI`
                            : null}
                    </Button>
                </>
            );
        }
        if (isPausedDistributor) {
            return (
                <Button
                    className={classNames("pools__button", {
                        _disabled: isTechnicalWorks,
                    })}
                    disabled
                >
                    Preparing the New Epoch
                </Button>
            );
        }
        return null;
    };

    const scrollContainer = (
        <div className="pools__container" id="pools-scroll">
            {!!poolsData.length &&
                poolsData.map((item) => {
                    return (
                        <DataContainer padding={24} className="pools__item" key={item.poolDuration}>
                            <div className="pools__item__content">
                                <div className="pools__item__content__header">
                                    <div className="pools__item__content__header__period">
                                        {item.poolDuration === 0 ? (
                                            <h3>zero pool:</h3>
                                        ) : (
                                            <h3>{item.poolDuration}+ months pool:</h3>
                                        )}
                                        {isLoading || (typeof item.totalPool === "string" && !item.totalPool) ? (
                                            <h3 className="pools__item-value">Loading...</h3>
                                        ) : (
                                            <h3 className="pools__item-value">
                                                {Number(item.totalPool).toLocaleString("en")} FITFI
                                            </h3>
                                        )}
                                    </div>
                                    <div className="pools__item__content__header__period">
                                        <h3>~added per month:</h3>
                                        {isLoading || (typeof item.poolPerMonth === "string" && !item.poolPerMonth) ? (
                                            <h3 className="pools__item-value">Loading...</h3>
                                        ) : (
                                            <h3 className="pools__item-value">
                                                {Number(item.poolPerMonth).toLocaleString("en")} FITFI
                                            </h3>
                                        )}
                                    </div>
                                </div>

                                <div className="pools__item__content__footer">
                                    <div className="pools__item__content__footer__user-data">
                                        <h3>Your tokens</h3>
                                        {isLoading || (typeof item.userTokens === "string" && !item.userTokens) ? (
                                            <h3 className="pools__item-value user__tokens">Loading...</h3>
                                        ) : (
                                            <h3 className="pools__item-value user__tokens">{item.userTokens} FITFI</h3>
                                        )}
                                    </div>

                                    <div className="pools__item__content__footer__user-data">
                                        <h3>APR</h3>
                                        {isLoading || !item.apr ? (
                                            <h3 className="pools__item-value">Loading...</h3>
                                        ) : (
                                            <h3 className="pools__item-value">
                                                {item.apr.toLowerCase() === "infinity" ||
                                                item.apr.toLowerCase() === "nan" ? (
                                                    <>&infin;</>
                                                ) : (
                                                    `${(+item.apr).toFixed(2)}%`
                                                )}
                                            </h3>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </DataContainer>
                    );
                })}
        </div>
    );

    if (!poolsData.length) {
        return null;
    }

    return (
        <div className="pools">
            <div className="pools__head-content">
                <div className="pools__head-content__text-container">
                    <h1 className="pools__head-content__headline">Pools</h1>
                    {isLoadingClaim || isLoadingDecimals || isLoadingDistributorState ? (
                        <h1 className="pools__head-content__headline pools__head-content__text-container__rewards">
                            Rewards: Loading...
                        </h1>
                    ) : (
                        <h1 className="pools__head-content__headline pools__head-content__text-container__rewards">
                            {`Rewards: 
                            ${tokenConverter(Number(claimInfo?.amount), fitfiDecimals, isClaimed).toLocaleString("en")} 
                            FITFI`}
                        </h1>
                    )}
                </div>

                <div className="pools__head-content__arrows">
                    <button className="pools__head-content__buttons" onClick={incrementActiveElement} type="button">
                        <ArrowIcon className="pools__head-content__arrows_left" />
                    </button>
                    <button className="pools__head-content__buttons" onClick={decrementActiveElement} type="button">
                        <ArrowIcon className="pools__head-content__arrows_right" />
                    </button>
                </div>
            </div>
            <div className="pools__container-wrapper" id="pools-container">
                {scrollContainer}
            </div>
            <div className="pools__button-wrapper">{renderPoolsButton()}</div>
        </div>
    );
};

export default PoolsComponent;
