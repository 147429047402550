import React from "react";
import Modal from "react-modal";

import classNames from "classnames";
import "./SnapshotModal.scss";
import Snapshot, { SnapshotProps } from "../Snapshot/Snapshot";
import { showDisclaimer } from "../../../../constants/texts";

interface SnapshotModalProps extends SnapshotProps {
    visible: boolean;
    onClose: () => void;
}

const SnapshotModal = ({ visible, onClose, ...props }: SnapshotModalProps) => {
    return (
        <Modal
            isOpen={visible}
            onRequestClose={onClose}
            className="withdraw-modal-container snapshot-modal-container"
            overlayClassName="withdraw-modal-container__overlay"
        >
            <div className="snapshot-modal-wrapper">
                <div
                    className={classNames("snapshot-modal", {
                        "snapshot-modal_activeDisclaimer": showDisclaimer,
                    })}
                >
                    <Snapshot {...props} />
                </div>
                <div
                    className={classNames("withdraw-modal__close", {
                        "snapshot-modal__closeButtonWithDisclaimer": showDisclaimer,
                    })}
                    onClick={onClose}
                >
                    CLOSE
                </div>
            </div>
        </Modal>
    );
};

export default SnapshotModal;
