import React, { useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import BN from "bn.js";

import DataContainer from "../../../DataContainer/DataContainer";

import "./StakeInput.scss";
import { ceilNumber, fromHRToBN, toHRNumberFloat } from "../../../../utils/bigNumber";
import { CurrentEpochDataType } from "../../../../utils/types";
import { getRewardPerDay } from "../../../../utils/time";
import { isTechnicalWorks } from "../../../../constants/texts";

interface StakeInputProps {
    value?: number;
    balance: number;
    stepStakeContract?: Contract;
    stepCalcContract?: Contract;
    decimals: number;
    staked: string | number;
    unstaked: string | number;
    onChange: (value?: number) => void;
    openWithdrawModal: () => void;
    openHowItWorksModal: () => void;
    epoch?: CurrentEpochDataType;
}

const StakeInput = ({
    epoch,
    value,
    balance,
    onChange,
    openWithdrawModal,
    staked,
    unstaked,
    stepStakeContract,
    stepCalcContract,
    decimals,
    openHowItWorksModal,
}: StakeInputProps) => {
    const [income, setIncome] = useState(0);
    const [inputState, setInputState] = useState<string | undefined>();

    const calcShares = async () => {
        if (value && stepStakeContract && stepCalcContract) {
            const shares = await stepStakeContract.methods.calculateShares(fromHRToBN(value, decimals)).call();
            const newIncome = await stepCalcContract.methods["rewardPerSecond(uint256,uint256)"](
                epoch?.epochIndex ?? 0,
                shares
            ).call();

            try {
                const res = toHRNumberFloat(getRewardPerDay(new BN(newIncome)), decimals);
                const ceilRes = ceilNumber(res);
                setIncome(ceilRes);
            } catch (e) {
                setIncome(0);
            }
        }
    };

    useEffect(() => {
        if (value && stepStakeContract) {
            calcShares();
        } else {
            setIncome(0);
        }
    }, [value]);

    // const handleStakeValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { value: inputValue } = e.target;

    //     if (inputValue === undefined) {
    //         onChange(undefined);
    //     } else {
    //         onChange(+inputValue);
    //     }
    // };

    const handleMaxClick = () => {
        if (balance) {
            setInputState(`${Math.floor(balance)}`);
        }
    };

    const setInputStateHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value: inputValue } = e.target;

        if (!inputValue || +inputValue < 1) {
            setInputState(``);
        } else {
            setInputState(`${Math.floor(+inputValue)}`);
        }
    };

    const disabledStyle = {
        opacity: 0.7,
        pointerEvents: "none",
    };

    useEffect(() => {
        if (inputState) {
            onChange(+inputState);
        } else {
            onChange(undefined);
        }
    }, [inputState]);

    return (
        <DataContainer className="stake-input">
            <div className="stake-input__line">
                <div>How much do you want to stake:</div>
                <div className="stake-input__withdraw-container">
                    <div className={isTechnicalWorks ? "stake-input--disabled" : ""} onClick={openWithdrawModal}>
                        Withdraw
                    </div>
                    <div onClick={openHowItWorksModal}>How it works?</div>
                </div>
            </div>

            <input
                type="number"
                className="stake-input__input"
                placeholder="0"
                name="stake_input"
                value={inputState}
                onChange={setInputStateHandler}
            />
            {!!income && (
                <div className="home-page__income">+{income.toLocaleString("en-us")} DROP TICKETS PER DAY</div>
            )}

            <div className="stake-input__info-data">
                <div className="stake-input__info-data__max" onClick={handleMaxClick}>
                    Max {Math.floor(balance).toLocaleString("en-us")} FITFI
                </div>
                <div className="stake-input__info-data_value">
                    <div>Unstaked by You: {unstaked.toLocaleString("en-us")} FITFI</div>
                    <div>Staked by You: {staked.toLocaleString("en-us")} FITFI</div>
                </div>
            </div>
        </DataContainer>
    );
};

export default StakeInput;
