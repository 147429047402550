import axios from "axios";

const responseWrapper = async (f: Promise<any>, needErrorMessage?: boolean) => {
    try {
        const response = await f;
        return response.data;
    } catch (e: any) {
        const errorMessage = e?.response?.data?.message ?? "Error";
        if (needErrorMessage) {
            return { responseError: errorMessage };
        }
    }
};

const credentialConfig = {
    withCredentials: true,
};

interface UserInformationType {
    floor: string;
    real: string;
}

export interface UserProofsType {
    amount: number;
    index: number;
    proofs: string[];
}

const ENDPOINT = "https://api.step.app"; // MAINNET
// const ENDPOINT = "https://api.step.app/dev"; // TESTNET
export const STAKING_API_ENDPOINT = "https://api-staking.step.app/v1/"; // MAINNET
// export const STAKING_API_ENDPOINT = "https://dev.api-staking.step.app/v1/"; // TESTNET

export const getUserInformation: (epoch: number, address: string) => Promise<UserInformationType> = async (
    epoch,
    address
) => responseWrapper(axios.get(`${ENDPOINT}/v1/epoch/${epoch}/wallet/${address}/tickets`, credentialConfig));

export const getUserProofs: (epoch: number, address: string) => Promise<UserProofsType> = async (epoch, address) =>
    responseWrapper(axios.get(`${ENDPOINT}/v1/epoch/${epoch}/wallet/${address}/proof`, credentialConfig));
