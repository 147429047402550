import BN from "bn.js";
import cn from "classnames";
import { ethers } from "ethers";
import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import StakeInput from "./supportComponents/StakeInput/StakeInput";

import StakeModal from "./supportComponents/StakeModal/StakeModal";
import WithdrawModal from "./supportComponents/WithdrawModal/WithdrawModal";

import ADDRESSES from "../../constants/address";
import ERC20 from "../../constants/ERC20.json";
import STEP_APP_CALC from "../../constants/StepAppCalculator.json";
import STEP_APP_STAKING from "../../constants/StepAppStaking.json";
import useDimensions from "../../hooks/useDimensions";
import { beautifyTokenBalance, ceilNumber, fromHRToBN, toHRNumberFloat } from "../../utils/bigNumber";
import { getRewardPerDay, getTimeLeft } from "../../utils/time";
import { CurrentEpochDataType, EpochDataType, StakeContractDataType, StakeDataType } from "../../utils/types";
import HowItWorksModal from "./supportComponents/HowItWorksModal/HowItWorksModal";
import SnapshotModal from "./supportComponents/SnapshotModal/SnapshotModal";

import {
    contractAddresses,
    isTechnicalWorks,
    lotteryHeldDate,
    snapshotDates,
    winnersUrls,
} from "../../constants/texts";
import useContextSelector from "../../contexts/contextSelector";
import useMulticall from "../../hooks/useMulticall";
import "./HomePage.scss";
import "./HomePageMobile.scss";
import HistoryModal from "./supportComponents/HistoryModal/HistoryModal";

interface HomePageProps {
    // web3: Web3;
    // currentAddress?: string;
    // onConnect: () => void;
    // closeHistoryModal: () => void;
    // isHistoryModalVisible: boolean;
}

const MAX_VALUE = "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff";

const HomePage = () => {
    const { width, height } = useDimensions();
    const [stakeValue, setStakeValue] = useState<number | undefined>(undefined);
    const [isHowItWorksModalVisible, setIsHowItWorksModalVisible] = useState(false);
    const [isWithdrawModalVisible, setIsWithdrawModalVisible] = useState(false);
    const [isStakeModalVisible, setIsStakeModalVisible] = useState(false);
    const [isSnapshotModalVisible, setIsSnapshotModalVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [msUntilEvent, setMsUntilEvent] = useState<number | undefined>(undefined);

    const [stakers, setStakers] = useState<StakeDataType[]>([]);
    const [stakerReward, setStakerReward] = useState(new BN(localStorage.getItem("rewards") ?? 0));
    const [epoch, setEpoch] = useState<CurrentEpochDataType | undefined>(undefined);
    const [currentUntilEvent, setCurrentUntilEvent] = useState<number | undefined>(undefined);
    const [stakerRewardPerSecond, setStakerRewardPerSecond] = useState(new BN(0));

    const [stepStakeContract, setStepStakeContract] = useState<Contract | undefined>(undefined);
    const [stepCalcContract, setStepCalcContract] = useState<Contract | undefined>(undefined);

    const [fitfiBalance, setFitfiBalance] = useState("0");
    const [fitfiDecimals, setFitfiDecimals] = useState(18);

    const [stepBalance, setTokenBalance] = useState("");
    const [unstakedBalance, setUnstakedBalance] = useState("");
    const [stepDecimals, setTokenDecimals] = useState(0);

    // eslint-disable-next-line no-undef
    const [intervalId, setIntervalId] = useState<NodeJS.Timeout | undefined>(undefined);

    const { web3Provider: web3, currentAddress, onConnect } = useContextSelector((state) => state.web3Provider);

    // const currentAddress = undefined;
    const multicall = useMulticall();

    useEffect(() => {
        if (web3) {
            setStepStakeContract(new web3.eth.Contract(STEP_APP_STAKING as any, ADDRESSES.STEP_APP_STAKING));
            setStepCalcContract(new web3.eth.Contract(STEP_APP_CALC as any, ADDRESSES.STEP_APP_CALCULATOR));
        }
    }, [web3]);

    useEffect(() => {
        if (msUntilEvent) {
            if (intervalId) {
                setIntervalId(undefined);
                clearInterval(intervalId);
                setCurrentUntilEvent(msUntilEvent);

                const newIntervalId = setInterval(() => {
                    setCurrentUntilEvent((v) => (v ? Math.max(v - 1000, 0) : msUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            } else {
                const newIntervalId = setInterval(() => {
                    setCurrentUntilEvent((v) => (v ? Math.max(v - 1000, 0) : msUntilEvent));
                }, 1000);

                setIntervalId(newIntervalId);
            }
        }

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [msUntilEvent]);

    const updateFitfiInfo = async () => {
        if (currentAddress && Web3.givenProvider) {
            Web3.givenProvider
                .request({ method: "eth_getBalance", params: [currentAddress, "latest"] })
                .then((balance: any) => setFitfiBalance(String(BigInt(balance ?? "0"))));
        }
    };

    const updateInformation = async () => {
        if (stepStakeContract && Web3.givenProvider && currentAddress && stepCalcContract) {
            updateFitfiInfo();
            const newTokenBalance = await stepStakeContract.methods.balanceOf(currentAddress).call();
            setTokenBalance(newTokenBalance);

            const unstakedResult = await stepStakeContract.methods.balanceOfUnstaked(currentAddress).call();
            setUnstakedBalance(unstakedResult);

            const newDecimals = await stepStakeContract.methods.decimals().call();
            setTokenDecimals(newDecimals);

            let newEpoch = (await stepCalcContract.methods.currentEpoch().call()) as CurrentEpochDataType;

            setEpoch(newEpoch);
            if (!newEpoch.isValid) {
                await stepCalcContract.methods
                    .epochs(1)
                    .call()
                    .then((res: any) => {
                        newEpoch = {
                            epochIndex: "1",
                            epoch: res,
                            isValid: false,
                        } as CurrentEpochDataType;
                    })
                    .catch((e: any) => console.log("epoch", e));
            }

            setMsUntilEvent(Math.max(+newEpoch.epoch.epochEndTimestamp * 1000 - Date.now(), 0));
            if (newEpoch.isValid) {
                const newReward = await stepCalcContract.methods
                    .rewardByStaker(newEpoch.epochIndex, currentAddress)
                    .call();

                setStakerReward(new BN(newReward));
                localStorage.setItem("rewards", String(newReward));
                setStakerRewardPerSecond(
                    new BN(
                        await stepCalcContract.methods["rewardPerSecond(uint256,address)"](
                            newEpoch.epochIndex,
                            currentAddress
                        ).call()
                    )
                );
            }

            const stakerStakeCount = await stepStakeContract.methods.stakerStakeCount(currentAddress).call();
            const stakingContract = new ethers.Contract(ADDRESSES.STEP_APP_STAKING, STEP_APP_STAKING);

            const stakingCalcContract = new ethers.Contract(ADDRESSES.STEP_APP_CALCULATOR, STEP_APP_CALC);
            const encodeResult = new Array(+stakerStakeCount).fill(1).map((item: any, index: any) => {
                return {
                    address: ADDRESSES.STEP_APP_STAKING,
                    callData: stakingContract.interface.encodeFunctionData("stakers", [currentAddress, index]),
                };
            });

            const callData = encodeResult.map((obj) => [obj.address, obj.callData]);
            const newStakers: StakeDataType[] = [];

            try {
                const [_, returnData] = await multicall.aggregate(callData);
                const result = returnData.reduce((response: any[], aggregateItemResult: any, i: number) => {
                    const data = stakingContract.interface.decodeFunctionResult("stakers", aggregateItemResult);
                    response[i] = data;
                    return response;
                }, []);

                let rewardResults;
                let rewardPerSecondResults;

                if (newEpoch.isValid) {
                    const rewardCallData = result.map((o: any, index: any) => {
                        return {
                            address: ADDRESSES.STEP_APP_CALCULATOR,
                            callData: stakingCalcContract.interface.encodeFunctionData(
                                "rewardByStake(uint256,address,uint256)",
                                [newEpoch.epochIndex, currentAddress, index]
                            ),
                            method: "rewardByStake(uint256,address,uint256)",
                        };
                    });
                    const rewardPerSecondCallData = result.map((o: any, index: any) => {
                        return {
                            address: ADDRESSES.STEP_APP_CALCULATOR,
                            callData: stakingCalcContract.interface.encodeFunctionData(
                                "rewardPerSecond(uint256,address,uint256)",
                                [newEpoch.epochIndex, currentAddress, index]
                            ),
                            method: "rewardPerSecond(uint256,address,uint256)",
                        };
                    });
                    const callRewardData: any[] = [...rewardCallData, ...rewardPerSecondCallData].map((obj) => [
                        obj.address,
                        obj.callData,
                    ]);
                    const [k, returnData2] = await multicall.aggregate(callRewardData);
                    const callRewardDataResult = returnData2.map((call: any, index: any) => {
                        return stakingCalcContract.interface.decodeFunctionResult(
                            [...rewardCallData, ...rewardPerSecondCallData][index].method,
                            call
                        );
                    });
                    rewardResults = callRewardDataResult.slice(0, rewardCallData.length);
                    rewardPerSecondResults = callRewardDataResult.slice(
                        rewardPerSecondCallData.length,
                        rewardCallData.length + rewardPerSecondCallData.length
                    );
                }
                const withdrawalsCallData = result.map((elem: any, index: number) => [
                    ADDRESSES.STEP_APP_STAKING,
                    stakingContract.interface.encodeFunctionData("withdrawals", [currentAddress, index]),
                ]);

                const [o, withdrawals] = await multicall.aggregate(withdrawalsCallData);
                const callRewardDataResult = withdrawals.map((call: any, index: any) => {
                    return stakingContract.interface.decodeFunctionResult("withdrawals", call);
                });
                for (let i = 0; i < result.length; ++i) {
                    const withdrawStatus = callRewardDataResult[i];
                    const unstaked = result[i][0];
                    if (!withdrawStatus.withdrawn) {
                        if (unstaked && Number(withdrawStatus.withdrawalTimestamp) === 0) {
                            // eslint-disable-next-line no-continue
                            continue;
                        }
                        const newStaker = {
                            ...result[i],
                            id: i,
                            amount: beautifyTokenBalance(String(result[i].amount), newDecimals),
                            withdrawTimestamp: withdrawStatus.withdrawalTimestamp,
                        };
                        if (newEpoch.isValid) {
                            newStaker.reward = new BN(rewardResults[i].reward.toString());
                            newStaker.rewardPerSecond = new BN(rewardPerSecondResults[i].reward.toString());
                        }
                        newStakers.push(newStaker);
                    }
                }
                setStakers(newStakers);
            } catch (error) {
                console.log("multicall error", error);
            }
        }
    };

    useEffect(() => {
        if (stepStakeContract && currentAddress && stepCalcContract) {
            updateInformation();
        }
    }, [currentAddress, stepStakeContract, stepCalcContract]);

    useEffect(() => {
        const updateIntervalId = setInterval(async () => {
            await updateInformation();
        }, 5000);

        return () => {
            clearInterval(updateIntervalId);
        };
    }, [currentAddress]);

    const updateInformationOnVisibilityChange = (event: Event) => {
        if (!document.hidden) {
            updateInformation();
        }
    };

    useEffect(() => {
        document.addEventListener("visibilitychange", updateInformationOnVisibilityChange);
    }, [currentAddress]);

    const handleStake = async () => {
        if (stepStakeContract && stepDecimals && stakeValue && stakeValue >= 1) {
            setIsLoading(true);
            try {
                const amountBN = fromHRToBN(stakeValue, stepDecimals).toString();
                await stepStakeContract?.methods.stake().send({ from: currentAddress, value: amountBN });
                setIsLoading(false);
                setStakeValue(0);
                await updateInformation();
            } catch (e) {
                console.error(e);
                setIsLoading(false);
            }
        }
    };

    const handleStakeValueChange = (value?: number) => {
        setStakeValue(value);
    };

    const openWithdrawModal = () => {
        setIsWithdrawModalVisible(true);
    };
    const openHowItWorksModal = () => {
        setIsHowItWorksModalVisible(true);
    };

    const closeWithdrawModal = () => {
        setIsWithdrawModalVisible(false);
    };
    const closeHowItWorksModal = () => {
        setIsHowItWorksModalVisible(false);
    };

    const openStakeModal = () => {
        setIsStakeModalVisible(true);
    };
    const closeStakeModal = () => {
        setIsStakeModalVisible(false);
    };

    const openSnapshotModal = () => {
        setIsSnapshotModalVisible(true);
    };
    const closeSnapshotModal = () => {
        setIsSnapshotModalVisible(false);
    };

    const epochIndex = epoch?.epochIndex ? +epoch.epochIndex : 0;

    const renderSnapshotModals = () => (
        <>
            {/* <HistoryModal
                visible={isHistoryModalVisible}
                onClose={closeHistoryModal}
                web3={web3}
                currentAddress={currentAddress}
                epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
            /> */}
            <SnapshotModal
                showRewards={false}
                epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
                web3={web3}
                currentAddress={currentAddress}
                visible={isSnapshotModalVisible}
                onClose={closeSnapshotModal}
                lotteryHeldDate={lotteryHeldDate}
                snapshotDate={snapshotDates[epochIndex - 1]}
                winnerUrl={winnersUrls[epochIndex - 1]}
                contractAddress={contractAddresses[epochIndex - 1]}
            />
        </>
    );

    const renderMobileButtons = () => {
        if (!currentAddress) {
            return (
                <div className="home-page-mobile__connect-button" onClick={onConnect}>
                    Connect wallet
                </div>
            );
        }

        return (
            <>
                <div
                    className={cn("home-page-mobile__stake-button", {
                        "home-page-mobile__stake-button--disabled":
                            isLoading || isTechnicalWorks || !stakeValue || stakeValue < 1,
                    })}
                    onClick={openStakeModal}
                >
                    {isLoading ? "Loading..." : "Stake"}
                </div>

                <div
                    className={cn("home-page-mobile__withdraw-button", {
                        "home-page-mobile__disabled-button": isTechnicalWorks,
                    })}
                    onClick={() => openWithdrawModal()}
                >
                    Withdraw
                </div>
                <div className="home-page-mobile__withdraw-button" onClick={openHowItWorksModal}>
                    How it works?
                </div>
            </>
        );
    };

    const rewardsNumber = ceilNumber(
        toHRNumberFloat(getRewardPerDay(stakerRewardPerSecond), stepDecimals)
    ).toLocaleString("en-us");

    const homepageMobile = () => {
        // const marginTop = Math.max(height - 670, 0);
        const marginTop = 0;

        return (
            <div className="home-page-mobile">
                <div className="home-page-mobile__section">
                    <div className="home-page-mobile__title">
                        {toHRNumberFloat(stakerReward, stepDecimals).toLocaleString("en-us")} DT #
                        {epoch ? +epoch.epochIndex + 1 : 0} (
                        <span className="home-page__title__old" onClick={openSnapshotModal}>
                            SNAPSHOT #{epoch ? +epoch.epochIndex : 0}
                        </span>
                        )
                    </div>
                    <div className="home-page-mobile__title-text">
                        <div>+{rewardsNumber} PER DAY</div>
                        <div>
                            {currentUntilEvent ? getTimeLeft(Math.floor(currentUntilEvent / 1000)) : 0}
                            <br />
                            UNTIL SNAPSHOT
                        </div>
                    </div>
                </div>
                <div className="home-page-mobile__section" style={{ marginTop }}>
                    {renderMobileButtons()}
                </div>
                <WithdrawModal
                    epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
                    decimals={stepDecimals}
                    onUpdate={updateInformation}
                    currentAddress={currentAddress}
                    stepContract={stepStakeContract}
                    stakers={stakers}
                    visible={isWithdrawModalVisible}
                    onClose={closeWithdrawModal}
                />
                <StakeModal
                    epoch={epoch}
                    stepCalcContract={stepCalcContract}
                    visible={isStakeModalVisible}
                    stepStakeContract={stepStakeContract}
                    decimals={stepDecimals}
                    onClose={closeStakeModal}
                    isLoading={isLoading}
                    balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
                    staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
                    unstaked={toHRNumberFloat(new BN(unstakedBalance), stepDecimals)}
                    onChange={handleStakeValueChange}
                    onStake={handleStake}
                    value={stakeValue}
                />
                <HowItWorksModal visible={isHowItWorksModalVisible} onClose={closeHowItWorksModal} />
                {renderSnapshotModals()}
            </div>
        );
    };

    // if (width <= 600) {
    //     const marginTop = Math.max(height - 670, 0);

    //     return (
    //         <div className="home-page-mobile">
    //             <div className="home-page-mobile__section">
    //                 <div className="home-page-mobile__title">
    //                     {toHRNumberFloat(stakerReward, stepDecimals).toLocaleString("en-us")} DT #
    //                     {epoch ? +epoch.epochIndex + 1 : 0} (
    //                     <span className="home-page__title__old" onClick={openSnapshotModal}>
    //                         SNAPSHOT #{epoch ? +epoch.epochIndex : 0}
    //                     </span>
    //                     )
    //                 </div>
    //                 <div className="home-page-mobile__title-text">
    //                     <div>+{rewardsNumber} PER DAY</div>
    //                     <div>
    //                         {currentUntilEvent ? getTimeLeft(Math.floor(currentUntilEvent / 1000)) : 0}
    //                         <br />
    //                         UNTIL SNAPSHOT
    //                     </div>
    //                 </div>
    //             </div>
    //             <div className="home-page-mobile__section" style={{ marginTop }}>
    //                 {renderMobileButtons()}
    //             </div>
    //             <WithdrawModal
    //                 epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
    //                 decimals={stepDecimals}
    //                 onUpdate={updateInformation}
    //                 currentAddress={currentAddress}
    //                 stepContract={stepStakeContract}
    //                 stakers={stakers}
    //                 visible={isWithdrawModalVisible}
    //                 onClose={closeWithdrawModal}
    //             />
    //             <StakeModal
    //                 epoch={epoch}
    //                 stepCalcContract={stepCalcContract}
    //                 visible={isStakeModalVisible}
    //                 stepStakeContract={stepStakeContract}
    //                 decimals={stepDecimals}
    //                 onClose={closeStakeModal}
    //                 isLoading={isLoading}
    //                 balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
    //                 staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
    //                 onChange={handleStakeValueChange}
    //                 onStake={handleStake}
    //                 value={stakeValue}
    //             />
    //             <HowItWorksModal visible={isHowItWorksModalVisible} onClose={closeHowItWorksModal} />
    //             {renderSnapshotModals()}
    //         </div>
    //     );
    // }

    return (
        <>
            <div className="home-page">
                <div className="home-page__title">
                    {toHRNumberFloat(stakerReward, stepDecimals).toLocaleString("en-us")} DT #
                    {epoch ? +epoch.epochIndex + 1 : 0} (
                    <span className="home-page__title__old" onClick={openSnapshotModal}>
                        SNAPSHOT #{epoch ? +epoch.epochIndex : 0}
                    </span>
                    )
                </div>
                <div className="home-page__title-text">
                    <div>+{rewardsNumber} PER DAY</div>
                    <div>
                        {currentUntilEvent ? getTimeLeft(Math.floor(currentUntilEvent / 1000)) : 0} UNTIL SNAPSHOT
                    </div>
                </div>
                {currentAddress && (
                    <div className="home-page__input-container">
                        <StakeInput
                            stepCalcContract={stepCalcContract}
                            epoch={epoch}
                            stepStakeContract={stepStakeContract}
                            decimals={stepDecimals}
                            balance={toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals)}
                            value={stakeValue}
                            staked={toHRNumberFloat(new BN(stepBalance), stepDecimals)}
                            onChange={handleStakeValueChange}
                            openWithdrawModal={openWithdrawModal}
                            openHowItWorksModal={openHowItWorksModal}
                            unstaked={toHRNumberFloat(new BN(unstakedBalance), stepDecimals)}
                        />
                        <div
                            className={cn("home-page__stake-button", {
                                "home-page__stake-button--disabled":
                                    isLoading ||
                                    isTechnicalWorks ||
                                    !stakeValue ||
                                    stakeValue > toHRNumberFloat(new BN(fitfiBalance), +fitfiDecimals) ||
                                    stakeValue < 1,
                            })}
                            onClick={handleStake}
                        >
                            {isLoading ? "Loading..." : "Stake"}
                        </div>
                    </div>
                )}
                {!currentAddress && (
                    <div className="home-page__connect-button" onClick={onConnect}>
                        Connect wallet
                    </div>
                )}
                <WithdrawModal
                    epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
                    decimals={stepDecimals}
                    onUpdate={updateInformation}
                    currentAddress={currentAddress}
                    stepContract={stepStakeContract}
                    stakers={stakers}
                    visible={isWithdrawModalVisible}
                    onClose={closeWithdrawModal}
                />
                <HowItWorksModal visible={isHowItWorksModalVisible} onClose={closeHowItWorksModal} />
                {renderSnapshotModals()}
            </div>
            {homepageMobile()}
        </>
    );
};

export default HomePage;
