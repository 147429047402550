import React, { FC, useCallback, useEffect, useState } from "react";
import { Contract } from "web3-eth-contract";
import ADDRESSES from "../../constants/address";
import useContextSelector from "../../contexts/contextSelector";
import Header from "../Header/Header";
import HistoryModal from "../HomePage/supportComponents/HistoryModal/HistoryModal";
import STEP_APP_CALC from "../../constants/StepAppCalculator.json";

import "./Layout.scss";
import { CurrentEpochDataType } from "../../utils/types";
import { showDisclaimer } from "../../constants/texts";

const Layout: FC = (props) => {
    const { children } = props;

    const [epoch, setEpoch] = useState<CurrentEpochDataType | undefined>(undefined);
    const [stepCalcContract, setStepCalcContract] = useState<Contract | undefined>(undefined);
    const { isHistoryModalVisible, setIsHistoryModalVisible } = useContextSelector((state) => state.historyModal);
    const { web3Provider: web3, currentAddress } = useContextSelector((state) => state.web3Provider);

    const closeHistoryModal = () => {
        setIsHistoryModalVisible(false);
    };

    const updateInformation = useCallback(async () => {
        if (currentAddress && stepCalcContract) {
            const newEpoch = (await stepCalcContract.methods.currentEpoch().call()) as CurrentEpochDataType;
            setEpoch(newEpoch);
        }
    }, [currentAddress, stepCalcContract]);

    useEffect(() => {
        if (web3) {
            setStepCalcContract(new web3.eth.Contract(STEP_APP_CALC as any, ADDRESSES.STEP_APP_CALCULATOR));
        }
    }, [web3]);
    useEffect(() => {
        updateInformation();
    }, [updateInformation]);
    return (
        <>
            <div className={showDisclaimer ? "header-wrapper-disclaimer" : "header-wrapper"}>
                <Header />
            </div>
            {children}
            <HistoryModal
                visible={isHistoryModalVisible}
                onClose={closeHistoryModal}
                web3={web3}
                currentAddress={currentAddress}
                epochIndex={epoch?.epochIndex ? +epoch.epochIndex : undefined}
            />
        </>
    );
};

export default Layout;
