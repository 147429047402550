import React, { useEffect, useState } from "react";
import ReactCardFlip from "react-card-flip";

import { StakeDataType } from "../../../../utils/types";
import WithdrawCard from "../WithdrawCard/WithdrawCard";
import WithdrawCardBack from "../WithdrawCardBack/WithdrawCardBack";

import { ceilNumber, toHRNumberFloat } from "../../../../utils/bigNumber";
import { getRewardPerDay, getTimeLeft } from "../../../../utils/time";
import WithdrawUnstaked from "../WithdrawCardBack/WithdrawUnstaked";
import "./WithdrawCardContainer.scss";

export interface WithdrawCardContainerProps {
    stakerData: StakeDataType;
    maxBPS: number;
    decimals: number;
    epochIndex?: number;
    withdrawForce: () => Promise<string>;
    withdraw: () => Promise<string>;
    onWithdraw: () => Promise<string>;
    cancelUnstake: () => Promise<string>;
}

const WithdrawCardContainer = ({
    onWithdraw,
    withdraw,
    stakerData,
    maxBPS,
    decimals,
    withdrawForce,
    epochIndex,
    cancelUnstake,
}: WithdrawCardContainerProps) => {
    const [isFlipped, setIsFlipped] = useState(false);
    const [monthsPool, setMonthsPool] = useState<number>();
    const { withdrawTimestamp, penaltyDays, amount, reward, rewardPerSecond } = stakerData ?? {};

    const [penaltyDate, setPenaltyDate] = useState<string | undefined>(undefined);
    const updatePenaltyDate = () => {
        if (withdrawTimestamp !== undefined && penaltyDays !== undefined) {
            const timestamp = +withdrawTimestamp - Math.floor(+new Date() / 1000);
            setPenaltyDate(timestamp > 0 ? getTimeLeft(timestamp) : undefined);
        }
    };

    useEffect(() => {
        updatePenaltyDate();
        const intervalId = setInterval(updatePenaltyDate, 100);

        return () => {
            clearInterval(intervalId);
        };
    }, [withdrawTimestamp, penaltyDays]);

    const toggleFlip = () => {
        setIsFlipped((v) => !v);
    };

    useEffect(() => {
        const curDate = Date.now() / 1000;
        const stakeDate = stakerData.stakedTimestamp;
        const ONE_MONTH = 2592000;
        [2, 6, 12].forEach((t) => {
            if (+(curDate - +stakeDate).toFixed(0) > t * ONE_MONTH) {
                setMonthsPool(t);
            }
        });
    }, [stakerData]);

    return (
        <>
            {!stakerData.unstaked ? (
                <ReactCardFlip isFlipped={isFlipped}>
                    <WithdrawCard
                        monthsPool={monthsPool}
                        epochIndex={epochIndex}
                        decimals={decimals}
                        maxBPS={maxBPS}
                        stakerData={stakerData}
                        onClick={toggleFlip}
                    />
                    <WithdrawCardBack
                        epochIndex={epochIndex}
                        decimals={decimals}
                        maxBPS={maxBPS}
                        stakerData={stakerData}
                        onAgree={onWithdraw}
                        onCancel={toggleFlip}
                    />
                </ReactCardFlip>
            ) : (
                <WithdrawUnstaked
                    monthsPool={monthsPool}
                    withdrawTimestamp={withdrawTimestamp}
                    withdraw={withdraw}
                    withdrawForce={withdrawForce}
                    cancelUnstake={cancelUnstake}
                    penaltyDate={penaltyDate}
                    maxBPS={maxBPS}
                    stakerData={stakerData}
                    onCancel={toggleFlip}
                />
            )}
        </>
    );
};

export default WithdrawCardContainer;
