import React, { useState } from "react";

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Web3Modal, { IProviderOptions } from "web3modal";
import { changeNetworkAtMetamask, NETWORK, networkInfo, networkToId } from "../utils/network";

const useWeb3Provider = () => {
    const providerOptions: IProviderOptions = {
        walletconnect: {
            package: WalletConnectProvider,
            options: {
                rpc: {
                    [networkInfo[NETWORK.stepnet].chainId]: networkInfo[NETWORK.stepnet].rpcUrls[0],
                },
            },
        },
    };
    // const web3Modal = new Web3Modal({ network: NETWORK.stepnet, cacheProvider: true, providerOptions });

    const [web3Provider, setWeb3Provider] = useState(new Web3(Web3.givenProvider));
    const [currentAddress, setCurrentAddress] = useState(undefined as string | undefined);
    const [isCorrectChainId, setIsCorrectChainId] = useState<boolean | undefined>(undefined);

    const [web3Modal, setWeb3Modal] = useState<Web3Modal>();

    const onDisconnect = async () => {
        // @ts-ignore
        if (web3Provider && web3Provider.currentProvider && web3Provider.currentProvider.close) {
            // @ts-ignore
            await web3Provider.currentProvider.close();
        }
        setCurrentAddress(undefined);
        await web3Modal?.clearCachedProvider();
    };

    const subscribeProvider = async (newProvider: any) => {
        if (!newProvider.on) {
            return;
        }
        newProvider.on("close", () => {
            onDisconnect();
        });
        newProvider.on("accountsChanged", async (accounts: string[]) => {
            setCurrentAddress(accounts[0]);
        });
        newProvider.on("chainChanged", async (hexChainId: string) => {
            const chainId = Web3.utils.hexToNumber(hexChainId ?? "");
            if (chainId === +networkToId[NETWORK.stepnet]) {
                await onConnect();
            } else {
                setIsCorrectChainId(false);
            }
        });
    };

    const onConnect = async () => {
        if (!window.ethereum) {
            window.open(`https://metamask.app.link/dapp/${window.location.href}`);
            return;
        }
        const web3ModalConnect =
            web3Modal ?? new Web3Modal({ network: NETWORK.stepnet, cacheProvider: true, providerOptions });
        const provider = await web3ModalConnect.connect();
        await subscribeProvider(provider);

        const newWeb3: Web3 = new Web3(provider);
        setWeb3Provider(newWeb3);
        await changeNetworkAtMetamask(NETWORK.stepnet);

        const accounts = await web3Provider.eth.getAccounts();
        const address = accounts[0];
        setCurrentAddress(address);
        const chainId = await web3Provider.eth.getChainId();
        setIsCorrectChainId(chainId === +networkToId[NETWORK.stepnet]);

        if (!web3Modal) {
            setWeb3Modal(web3ModalConnect);
        }
    };

    return { provider: web3Provider, currentAddress, isCorrectChainId, onConnect, onDisconnect };
};

export default useWeb3Provider;
