import classNames from "classnames";
import React from "react";
import Modal from "react-modal";
import { showDisclaimer } from "../../../../constants/texts";

import "./HowItWorksModal.scss";

interface HowItWorksModalContainerProps {
    visible: boolean;
    onClose: () => void;
}

const HowItWorksModal = ({ visible, onClose }: HowItWorksModalContainerProps) => {
    return (
        <Modal
            isOpen={visible}
            onRequestClose={onClose}
            className="withdraw-modal-container"
            overlayClassName="withdraw-modal-container__overlay"
        >
            <div
                className={classNames("how-it-works-modal", {
                    showedDisclaimer: showDisclaimer,
                })}
            >
                <div className="how-it-works-modal__title">HOW IT WORKS</div>
                <div className="how-it-works-modal__card">
                    <div className="how-it-works-modal__card__title">Step App Perpetual Staking</div>
                    <div className="how-it-works-modal__card__text">
                        <p>
                            The Step App staking dashboard offers the first of its kind perpetual staking. You stake
                            once to get access to any opportunities that come. Lootboxes, yield, sponsored SNEAK drops,
                            and pretty much everything else — starts here.
                        </p>
                        <p>
                            The staking campaign begins with Lootboxes. Rather than sell the first NFTs to the
                            community, we maintain our community-centric approach and offer these for free to the early
                            adopters.
                        </p>
                        <ol>
                            <li>Stake $FITFI</li>
                            <li> Earn Drop Tickets (DT)</li>
                            <li>For every 100 $FITFI staked per 24h, you earn one DT.</li>
                            <li> At the end of the Lootbox event, a snapshot is taken and a lottery takes place</li>
                            <li> Lootboxes are awarded</li>
                            <li>
                                You get bonus Drop Tickets for higher stakes, but no single address can win more than 5
                                Lootboxes
                            </li>
                            <li>
                                You get bonus Drop Tickets for higher stakes, but no single address can win more than 5
                                Lootboxes
                            </li>
                            <li> Lootboxes contain SNEAKs, gems, energy or $KCAL</li>
                            <li>
                                Events keep looping: Lootbox events can be followed by different Lootbox events, yield
                                events, brand drops, and other opportunities
                            </li>
                        </ol>
                        <p>
                            You do not need to re-stake for future events.This staking is perpetual and rolls over to
                            the next event automatically.
                        </p>
                        <p>
                            The staking is flexible and has no lock. You can leave whenever you like. Staking does have
                            a short cooldown period of 14 days, meaning once you want to exit, you have to wait 14 days.
                        </p>
                        <p>
                            Anyone who wants to immediately unstake can do so aswell. This skips the 14 days of
                            cooldown. The penalty of that is 16%. This amount goes to the DAO treasury, which can vote
                            to do anything with this amount. Even burn it.
                        </p>
                        <p>
                            We are excited to bring the industry the first of its kind perpetual staking contract. We
                            believe this form of staking is in the best interest of the community as a one-time stake
                            opens opportunities to almost everything happening in the ecosystem.
                        </p>
                    </div>
                </div>
            </div>
            <div
                className={classNames("withdraw-modal__close", {
                    "withdraw-modal__activeDisclaimer": showDisclaimer,
                })}
                onClick={onClose}
            >
                CLOSE
            </div>
            <div className="how-it-works-modal-space" onClick={onClose} />
        </Modal>
    );
};

export default HowItWorksModal;
