import React, { useEffect, useState } from "react";
import Web3 from "web3";
import { Contract } from "web3-eth-contract";
import classNames from "classnames";

import { getUserInformation, getUserProofs, UserProofsType } from "../../../../utils/api";
import MERKLE_DISTRIBUTOR from "../../../../constants/MerkleDistributor.json";
import { floorNumber } from "../../../../utils/bigNumber";

import "./Snapshot.scss";
import { showDisclaimer } from "../../../../constants/texts";

export interface SnapshotProps {
    epochIndex?: number;
    web3: Web3;
    currentAddress?: string;
    snapshotDate: string;
    lotteryHeldDate?: string;
    showRewards?: boolean;
    winnerUrl?: string;
    contractAddress: string;
    timeout?: number;
}

const Snapshot = ({
    epochIndex,
    web3,
    currentAddress,
    snapshotDate,
    lotteryHeldDate,
    showRewards,
    winnerUrl,
    contractAddress,
    timeout = 0,
}: SnapshotProps) => {
    const [stakerReward, setStakerReward] = useState<number>();
    const [tickets, setTickets] = useState(0);
    const [userProofs, setUserProofs] = useState<UserProofsType | undefined | null>(null);
    const [isClaimed, setIsClaimed] = useState<boolean | undefined>(undefined);
    const [merkleContract, setMerkleContract] = useState<Contract | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);

    const onUpdate = async () => {
        if (currentAddress && epochIndex !== undefined) {
            setIsLoading(true);
            const newMerkleContract = new web3.eth.Contract(MERKLE_DISTRIBUTOR as any, contractAddress);
            setMerkleContract(newMerkleContract);

            const information = await getUserInformation(epochIndex, currentAddress);
            setStakerReward(information ? floorNumber(+information?.real) : undefined);
            setTickets(Math.floor(+information?.floor || 0));

            // const proofs = {
            //     amount: 15,
            //     index: 2,
            //     proofs: [""],
            // };
            const proofs = await getUserProofs(epochIndex, currentAddress);
            setUserProofs(proofs);
            if (proofs?.amount) {
                setIsClaimed(await newMerkleContract.methods.isClaimed(proofs.index).call());
            }

            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (epochIndex !== undefined) {
            setTimeout(onUpdate, timeout);
        }
    }, [currentAddress, epochIndex]);

    const handleClaim = async () => {
        if (userProofs && merkleContract) {
            await merkleContract.methods
                .claim(userProofs.index, currentAddress, userProofs.amount, userProofs.proofs)
                .send({ from: currentAddress });
            await onUpdate();
        }
    };

    const handleOpenList = () => {
        window.open(winnerUrl, "_blank");
    };

    return (
        <div
            className={classNames("snapshot", {
                snapshot__showDisclaimer: showDisclaimer,
            })}
        >
            <div className="snapshot__title">
                SNAPSHOT #{epochIndex} WAS TAKEN ON {snapshotDate}
            </div>
            {showRewards && (
                <>
                    <div className="snapshot__value">
                        {stakerReward === undefined ? "SOON" : stakerReward.toLocaleString("en-us")}
                    </div>
                    <div className={classNames("snapshot__description", { snapshot__withDisclaimer: showDisclaimer })}>
                        DROP TICKETS #{epochIndex}
                    </div>
                    <div className="snapshot__text">
                        <p>The lottery will be held on {lotteryHeldDate}.</p>
                        <p>A separate post will be made with the details of a trustless selection explainer.</p>
                        <p>There will be a button here where winners will be able to claim their lootboxes.</p>
                    </div>
                </>
            )}
            {!showRewards && (
                <>
                    {isLoading && userProofs === null && isClaimed === undefined && (
                        <>
                            <div className="snapshot__value">Loading...</div>
                            <div
                                className={classNames("snapshot__description", {
                                    snapshot__withDisclaimer: showDisclaimer,
                                })}
                                style={{ paddingBottom: 100 }}
                            >
                                There’s no connection
                            </div>
                            <div className="snapshot__text">
                                <p>Please connect your wallet to see whether you have won loot boxes.</p>
                                <p>
                                    Our staking is perpetual, therefore, all people who staked their FITFI will have a
                                    chance in the upcoming event.
                                </p>
                            </div>
                        </>
                    )}

                    {userProofs?.amount && (
                        <>
                            <div className="snapshot__value">Hooraay</div>
                            <div
                                className={classNames("snapshot__description", {
                                    snapshot__withDisclaimer: showDisclaimer,
                                })}
                                style={{ paddingBottom: 10 }}
                            >
                                You’ve won
                            </div>
                            <div className="snapshot__lootbox">
                                <img className="snapshot__lootbox__image" src={`/img/lootbox${epochIndex}.png`} />
                                <div className="snapshot__lootbox__text">x {userProofs.amount}</div>
                            </div>
                            <div className="snapshot__text">
                                <p>
                                    You had {tickets} Lottery Tickets and won {userProofs.amount} loot boxes!
                                    Congratulations.
                                </p>
                                <p>
                                    Keep going! Staking is perpetual and you can win on the next round aswell, along
                                    with earning yield.
                                </p>
                                <p>
                                    {isClaimed
                                        ? "You have already claimed your prize " +
                                          "so stay tuned for updates regarding the revealing event."
                                        : "Please click on the Claim button " +
                                          "and stay tuned for updates regarding the revealing event."}
                                </p>
                            </div>
                        </>
                    )}

                    {(userProofs?.amount === 0 || userProofs === undefined) && (
                        <>
                            <div className="snapshot__value">Sorry</div>
                            <div
                                className={classNames("snapshot__description", {
                                    snapshot__withDisclaimer: showDisclaimer,
                                })}
                            >
                                You haven’t won
                            </div>
                            <div className="snapshot__text">
                                <p>
                                    You had {tickets} Lottery Tickets but unfortunately did not win this round of the
                                    lottery.
                                </p>
                                <p>
                                    Do not worry: our staking is perpetual, therefore, all people who staked their FITFI
                                    will have a chance in the upcoming event.
                                </p>
                            </div>
                        </>
                    )}

                    <div className="snapshot__buttons">
                        {userProofs && isClaimed && (
                            <div className="snapshot__button--claim snapshot__button--disable">Claimed</div>
                        )}
                        {userProofs && !isClaimed && (
                            <div className="snapshot__button--claim" onClick={handleClaim}>
                                Claim
                            </div>
                        )}
                        {/* <div className="snapshot__button--view" onClick={handleOpenList}> */}
                        {/*    View Winner List */}
                        {/* </div> */}
                    </div>
                </>
            )}
        </div>
    );
};

export default Snapshot;
