import React, { useEffect, useState } from "react";

import ReactCardFlip from "react-card-flip";
import { StakeDataType } from "../../../../utils/types";
import DataContainer from "../../../DataContainer/DataContainer";

import useDimensions from "../../../../hooks/useDimensions";
import { ceilNumber, toHRNumberFloat } from "../../../../utils/bigNumber";
import { getRewardPerDay, getTimeLeft } from "../../../../utils/time";
import WithdrawUnstaked from "../WithdrawCardBack/WithdrawUnstaked";
import "./WithdrawCard.scss";

interface WithdrawCardProps {
    stakerData?: StakeDataType;
    maxBPS: number;
    decimals: number;
    epochIndex?: number;
    onClick: () => void;
    monthsPool: number | undefined;
}

const WithdrawCard = ({ stakerData, onClick, maxBPS, decimals, epochIndex, monthsPool }: WithdrawCardProps) => {
    const { width } = useDimensions();
    const { stakedTimestamp, amount, reward, rewardPerSecond } = stakerData ?? {};

    const date = new Date(+(stakedTimestamp ?? 0) * 1000);

    const rewardPerDay = rewardPerSecond
        ? ceilNumber(toHRNumberFloat(getRewardPerDay(rewardPerSecond), decimals)).toLocaleString("en-us")
        : 0;

    return (
        <DataContainer className="withdraw-card" padding={width > 600 ? 24 : 18} onClick={onClick}>
            <div className="withdraw-card__date">
                {date.toLocaleDateString()} {monthsPool ? `: ${monthsPool}+ MONTHS POOL` : null}
            </div>
            <div className="withdraw-card__value">{(amount ?? 0).toLocaleString("en-us")} FITFI</div>
            <div className="withdraw-card__points">
                {reward ? toHRNumberFloat(reward, decimals).toLocaleString("en-us") : 0} DT #{(epochIndex ?? 0) + 1}
            </div>
            <div className="withdraw-card__pointPerDay">{rewardPerDay} PER DAY</div>
            <div className="withdraw-card__line withdraw-card__line--withdraw">Unstake</div>
        </DataContainer>
    );
};

export default WithdrawCard;
