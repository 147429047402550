import React from "react";
import Modal from "react-modal";
import Web3 from "web3";

import "./HistoryModal.scss";
import classNames from "classnames";
import Snapshot from "../Snapshot/Snapshot";
import {
    contractAddresses,
    lotteryHeldDate,
    showDisclaimer,
    snapshotDates,
    winnersUrls,
} from "../../../../constants/texts";
import useContextSelector from "../../../../contexts/contextSelector";

interface SnapshotModalProps {
    visible: boolean;
    onClose: () => void;
    epochIndex?: number;
    web3: Web3;
    currentAddress?: string;
}

const HistoryModal = ({ visible, onClose, epochIndex, web3, currentAddress }: SnapshotModalProps) => {
    const { isHistoryModalVisible } = useContextSelector((state) => state.historyModal);
    const arr = Array(epochIndex)
        .fill(0)
        .map((_, i) => i + 1)
        .reverse();

    return (
        <Modal
            isOpen={isHistoryModalVisible}
            onRequestClose={onClose}
            className="withdraw-modal-container history-modal-container"
            overlayClassName="withdraw-modal-container__overlay"
        >
            <div className="history-modal-wrapper">
                {arr.length > 0 && (
                    <div className="history-modal">
                        {arr.map((arrEpochIndex) => (
                            <Snapshot
                                key={arrEpochIndex}
                                web3={web3}
                                currentAddress={currentAddress}
                                epochIndex={arrEpochIndex}
                                snapshotDate={snapshotDates[arrEpochIndex - 1]}
                                contractAddress={contractAddresses[arrEpochIndex - 1]}
                                winnerUrl={winnersUrls[arrEpochIndex - 1]}
                                showRewards={false}
                                lotteryHeldDate={lotteryHeldDate}
                                timeout={epochIndex ? (epochIndex - arrEpochIndex) * 1000 : 0}
                            />
                        ))}
                    </div>
                )}
                <div
                    className={classNames("withdraw-modal__close", {
                        "withdraw-modal__activeDisclaimer": showDisclaimer,
                    })}
                    onClick={onClose}
                >
                    CLOSE
                </div>
            </div>
        </Modal>
    );
};

export default HistoryModal;
