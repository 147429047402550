export const poolsDuration = [0, 2, 6, 12, 24];

export const secondsInMonth = 60 * 60 * 24 * 30;

const second = 1000;
const minute = second * 60;
const hour = minute * 60;
const day = hour * 24;
const month = day * 30;

const stakesMockData = [
    {
        unstaked: false,
        amount: "100000000000000000000000",
        stakedTimestamp: "1651829922",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "150000000000000000000000",
    },
    {
        unstaked: false,
        amount: "10000000000000000000000",
        stakedTimestamp: "1651837248",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "10500000000000000000000",
    },
    {
        unstaked: false,
        amount: "1000000000000000000000",
        stakedTimestamp: "1651837304",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "1005000000000000000000",
    },
    {
        unstaked: false,
        amount: "100000000000000000000",
        stakedTimestamp: "1651837338",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "100050000000000000000",
    },
    {
        unstaked: false,
        amount: "1000000000000000000000000",
        stakedTimestamp: "1651857095",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "6000000000000000000000000",
    },
    {
        unstaked: false,
        amount: "1000000000000000000000000",
        stakedTimestamp: "1653048223",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "6000000000000000000000000",
    },
    {
        unstaked: false,
        amount: "100000000000000000000",
        stakedTimestamp: "1653426376",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "100050000000000000000",
    },
    {
        unstaked: false,
        amount: "1000000000000000000000",
        stakedTimestamp: "1653431111",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "1005000000000000000000",
    },
    {
        unstaked: false,
        amount: "10000000000000000000000",
        stakedTimestamp: "1653738618",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "10500000000000000000000",
    },
    {
        unstaked: false,
        amount: "1000000000000000000000",
        stakedTimestamp: "1655473205",
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "1005000000000000000000",
    },
    {
        unstaked: true,
        amount: "400000000000000000000",
        stakedTimestamp: 0,
        penaltyDays: "14",
        penaltyBP: "1600",
        shares: "6000000000000000000000000",
    },
];

// for (let pool = 0; pool < 3 * month; ) {
//     const stake = {
//         unstaked: false,
//         amount: (Math.random() * 10 ** 20).toString(),
//         stakedTimestamp: (1657130121861 - pool) / 1000,
//         penaltyDays: "14",
//         penaltyBP: "1600",
//         shares: "6000000000000000000000000",
//     };

//     stakesMockData.push(stake);

//     pool += day;
// }
export { stakesMockData };
