import BN from "bn.js";

export const beautifyTokenBalance = (balance: string, decimals: number, fraction = 5) => {
    const exp = 10 ** fraction;

    return (+balance.slice(0, -decimals + fraction) / exp).toLocaleString(undefined, {
        minimumFractionDigits: 0,
        maximumFractionDigits: fraction,
    });
};

export const fromHRToBN = (n: number, decimals: number) => {
    const MAX_SMALL_DECIMAL = 6;
    if (decimals <= MAX_SMALL_DECIMAL) {
        return new BN(10).pow(new BN(decimals)).muln(n);
    }

    const multiplierSmall = new BN(10).pow(new BN(MAX_SMALL_DECIMAL));
    const multiplierMain = new BN(10).pow(new BN(decimals - MAX_SMALL_DECIMAL));

    return multiplierSmall.mul(new BN(n)).mul(multiplierMain);
};

export const toHRNumber = (bn: BN, decimal: number) => {
    if (!decimal) {
        return 0;
    }
    return bn.div(new BN(10).pow(new BN(decimal))).toNumber();
};
export const toHRNumberFloat = (bn: BN, decimal = 0) => toHRNumber(bn.muln(1000), decimal) / 1000;

export const ceilNumber = (n: number) => Math.ceil(n * 100) / 100;

export const floorNumber = (n: number) => Math.floor(n * 100) / 100;
